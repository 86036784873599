// @flow

import React from 'react'
import type { Element, Node } from 'react'

import BodyText from '../../../components/typography/body-text'

type Props = {
  children: Node,
}

function ListItem({ children }: Props): Element<'li'> {
  return (
    <li className="mb-1 py-2 px-3 bg-grey-lighter rounded leading-tight">
      <BodyText>{children}</BodyText>
    </li>
  )
}

export default ListItem
