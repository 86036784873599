// @flow

import React from 'react'
import type { Node } from 'react'

import { graphql, useStaticQuery } from 'gatsby'

import { CreateListing } from '../../scenes/listing'
import { sunOverview } from '../../data/cottages-content/sun'
import { getFormattedData } from '../../helpers'

import type { CottagePageContent } from '../../types'

type QueryResponse = {|
  sunCottagePage: CottagePageContent,
|}

function SunCottagePage(): Node {
  const data: QueryResponse = useStaticQuery(graphql`
    query SunCottagePageQuery {
      sunCottagePage: contentfulCottagePage(title: { eq: "Sun" }) {
        id
        title
        featuredImage {
          id
          title
          description
          image: resize(width: 1800, quality: 80) {
            src
          }
        }
        cottageImages {
          id
          description
          image: resize(width: 800, quality: 80) {
            src
          }
        }
      }
    }
  `)

  const { heroImage, cottageImages } = getFormattedData(data.sunCottagePage)

  return (
    <CreateListing
      id="sun-cottage"
      cottageName="Sun"
      featuredImageUrl={heroImage.src}
      overview={sunOverview}
      welcomeMessage="Welcome to the Sun Cottage."
      images={cottageImages}
    />
  )
}

export default SunCottagePage
