// @flow

import React from 'react'
import type { Element } from 'react'
import { map } from 'ramda'

import { generateIdFromTitle } from '../../../helpers'
import type { OverviewItem } from '../../../types/cottage-page'
import ListItem from './list-item'

const roomConfigDetails = [
  '3 Bedroom (1 K, 2 T, 2 T) 3 Bath.',
  'Sleeper sofa in living room on the main floor.',
  '3rd Bedroom is a separate suite (2 T) on the ground level.',
  'Comfortably sleeps 8.',
]

const roomConfig: Element<'ul'> = (
  <ul>
    {roomConfigDetails.map((item) => (
      <ListItem key={item}>{item}</ListItem>
    ))}
  </ul>
)

const overview = [
  {
    title: 'Incredible Views',
    message:
      'Bright, airy, tastefully decorated Sun cottage has incredible gulf views.',
  },
  {
    title: 'Beautiful Living Area',
    message: 'Roomy deck and gourmet kitchen.',
  },
  {
    title: 'Room Configuration',
    message: roomConfig,
  },
]

export const sunOverview: OverviewItem[] = map(generateIdFromTitle, overview)

export default sunOverview
